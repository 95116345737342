<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Ffull-symphony%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        A tropical oasis set in the middle of Europe’s biggest performing arts
        centre, the Barbican Conservatory is home to a huge variety of tropical
        flora and fauna.
      </h3>
      <p>
        The Barbican is mainly known for its brutalist towers rather than it’s
        tropical plant collection, but the conservatory is a treasure trove of
        exotic florae, a real thriving oasis in the heart of a built-up area of
        London.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img src="@/assets/images/locations/02-barbican/body-1.png" alt="" />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
        <p>
          While London is technically a forest, with 8.4m trees in the city, it
          can sometimes feel like nature is an afterthought: Hafsah thinks that
          this could be changing: “For so many years councils and governments have
          prioritised buildings, roads, infrastructure whilst destroying crucial
          habitats, flora and fauna. Nature has become an ‘other’ over lockdown we
          have seen a heightened sense of understanding around its importance and
          using this momentum we need to support the brilliant green places around
          us”
        </p>
      </div>
      <blockquote>
        <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
          "Creating green spaces in urban areas can have a huge impact on
          supporting local biodiversity"
        </div>
      </blockquote>
      <div class="content">
        <h2>Connecting</h2>
        <p>
          Over the last 18 months, there’s been a new appreciation of the simpler
          things. A recent Sonos report shows the importance that listening to
          music and being outdoors has in connecting with our environment in a
          meaningful way.
        </p>
      </div>
      <div class="tile-lower">
        <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
          <picture>
            <source
              srcset="@/assets/images/body-tile-one-lg.png"
              media="(min-width: 450px)"
            />
            <img src="@/assets/images/locations/02-barbican/body-2.png" alt="" />
          </picture>
        </div>
        <div
          v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
          class="prlx-tile-lower-graphic"
        >
          <picture>
            <source
              srcset="@/assets/images/about-tile-one.png"
              media="(min-width: 450px)"
            />
            <img src="@/assets/images/location-body-pattern.png" alt="" />
          </picture>
          <div class="highlights">
            <h3>Did you know?</h3>
            <ul>
              <li>
                Oliver Cromwell was married, and poet John Milton buried, in St
                Giles Church on the Barbican estate.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <p>
          So are places like the Barbican garden essential in helping people keep
          up this new found joy in nature? Hafsah continues: “Creating green
          spaces in urban areas where most of these habitats have been destroyed
          can have a huge impact on supporting local biodiversity as well as
          become a place for people to engage and connect with the natural world.”
          </p>
          <p>
            <a href="https://tfl.gov.uk/modes/walking/jubilee-walkway" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
          </p>
        <img
              src="@/assets/images/locations/02-barbican/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">


            <img
              src="@/assets/images/locations/02-barbican/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/02-barbican/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/02-barbican/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Barbican",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>