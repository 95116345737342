<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Ffeverish-fantazmagoria-curated-by-dangelo%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        In the west corner of London’s vast Hampstead Heath heartland, you’ll
        find Golders Hill Park. A grassy spot, with lots of green spaces and
        plenty to do, it’s an ideal place to meet friends, gather and enjoy some
        time outdoors.
      </h3>
      <p>
        Places like Golders Hill Park are essential for bringing people
        together, whether it’s for a walk, a visit to the free zoo, or just
        listening to music by the pond. The quality sound of the Sonos Roam can
        enhance a calming moment, for an even more positive experience.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/08-goldershill/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Ellen believes music and nature combined have the power to bring people
        together: “Music has an incredible power to change the atmosphere and
        take you into a certain headspace, so by listening to something
        together, you’re automatically aligning your moods.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Contact with nature should be a right for all, not a privilege"
      </div>
    </blockquote>
    <div class="content">
      <h2>Education</h2>
      <p>
        Outdoor experiences are incredibly beneficial to a healthy life. Ellen
        continues: “Nature is vital to our health and happiness – as crucial as
        exercise and a balanced diet. It's a key factor in keeping your immune
        system running, your mental health lifted and your brain-power sharp.”
      </p>

      <p>
        A visit to Golders Park is a treat in itself, and for groups who want to
        wander further, then the whole of Hampstead Heath is right there, with
        its sprawling green spaces and famous bathing ponds.
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/08-goldershill/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              Hampstead Heath is a Site of Metropolitan Importance for Nature
              Conservation.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        Such open spaces are wonderful for mental health and wellbeing, and it’s
        important that they are open and available for anyone to visit,
        something that Ellen believes strongly in: “Obviously, given the
        critical benefits it has on our bodies, minds and social bonds, contact
        with nature should be a right for all, not a privilege.”
         </p>
             <p>
          <a href="https://tfl.gov.uk/modes/walking/capital-ring" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/08-goldershill/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/08-goldershill/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/08-goldershill/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/08-goldershill/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoldersHill",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>