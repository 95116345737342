<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Falways-read-the-label-factory-records%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        Nestled in a quiet corner of Haggerston Park, you’ll find Haggerston
        Orchard, a community-run vegetable garden, designed to create a
        sustainable fruit and nut orchard in the heart of bustling East London.
      </h3>
      <p>
        This special sanctuary is run entirely by volunteers, and provides a
        safe space to members of the local community to plant and grow
        vegetables. Theo, from Water Daily, sees the idea of the community
        garden, and the opportunity for gardening that Haggerston Orchard
        provides, as an essential part of inner-city life: “For many of us
        living in cities, we are disconnected from nature. It’s only in the last
        hundred years or so that we’ve become removed from our outdoor
        environments, so this disconnection is relatively new to us.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/13-haggerston/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Haggerston Orchard uses recycled products to build their beds, and
        harvests rainwater to feed the plants and vegetables, and is designed to
        flourish with natural, permaculture techniques.
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Cultivating our local green spaces helps bring communities and people
        back together"
      </div>
    </blockquote>
    <div class="content">
      <h2>Cultivating</h2>
      <p>
        Water Daily’s Jess sees spaces like Haggerston Orchard as key to
        bringing people back to the outside after a difficult year, much of
        which has been spent inside: “Cultivating our local green spaces helps
        bring communities and people back together, which is even more important
        after months of being cut off from each other.”
      </p>
      <p>
        Theo thinks that music can enhance this community experience: “As a
        group you are not only united in the work you are doing but also in what
        you are listening to. That level of connection is something we all yearn
        for I think. Music is a conversation starter and a good way to find out
        things in common with people you might be just getting to know.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/13-haggerston/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              Haggerston Park was made on the site of the old Shoreditch
              Gasworks, which had been destroyed by a V2 rocket in World War 2.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        As the world starts to come back to life after the lockdowns, spots like
        Haggerston Orchard are nurturing more than just the soil. They’re
        feeding a community spirit. So choose your playlist, and let music
        enhance the feeling of joy and wonder as you get back to nature.
      </p>
      <p>
        For further information on how to volunteer at the Haggerston Orchard,
        please email:
        <a href="mailto:thehaggerstonorchard@gmail.com"
          >thehaggerstonorchard@gmail.com</a
        >.
           </p>
              <p>
          <a href="https://tfl.gov.uk/modes/walking/jubilee-greenway" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/13-haggerston/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/13-haggerston/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/13-haggerston/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/13-haggerston/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Haggerston",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>