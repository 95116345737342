<template>
  <div id="app">
    <header
      v-bind:class="{ submenu: subMenu && !showMenu, scrollmenu: scrollMenu }"
    >
      <div class="menu-wrapper">
        <router-link to="/">
          <img
            src="@/assets/images/arrow-back.svg"
            alt="back"
            class="back-arrow"
          />
        </router-link>
        <div
          id="menu"
          v-on:click="showMenu = !showMenu"
          v-bind:class="{ open: showMenu }"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="subnav">
        <p class="current-location">{{ currentLocation.title }}</p>
        <ul>
          <li>
            <a
              class="btn"
              v-bind:class="{ active: currentTab === 1 }"
              v-on:click="setActiveTab(1)"
              >About</a
            >
          </li>
          <li>
            <a
              class="btn"
              v-bind:class="{ active: currentTab === 2 }"
              v-on:click="setActiveTab(2)"
              >Listen</a
            >
          </li>
          <li>
            <a
              class="btn"
              v-bind:class="{ active: currentTab === 3 }"
              v-on:click="setActiveTab(3)"
              >Info</a
            >
          </li>
        </ul>
      </div>
    </header>
    <nav
      class="main-nav"
      v-bind:class="{ active: showMenu }"
      v-on:click="showMenu = false"
    >
      <div class="main-nav-content">
        <img
          src="@/assets/images/home-hero-pattern-top.png"
          class="nav-hero-top"
        />
        <div class="main-nav-wrap">
          <ul>
            <li>
              <router-link to="/"
                >Discover <img src="@/assets/images/right-arrow.svg"
              /></router-link>
            </li>
            <li>
              <router-link to="/about"
                >About <img src="@/assets/images/right-arrow.svg"
              /></router-link>
            </li>
            <li>
              <router-link to="/partners"
                >Partners <img src="@/assets/images/right-arrow.svg"
              /></router-link>
            </li>
            <li>
              <router-link to="/resources"
                >Resources <img src="@/assets/images/right-arrow.svg"
              /></router-link>
            </li>
          </ul>
          <div class="lower">
            <h5>#RoamAndExplore</h5>
            <p>Your guide to a greener life in London</p>
          </div>
        </div>
      </div>
    </nav>
    <router-view />
    <footer class="main-footer">
      <h5>#RoamAndExplore</h5>
      <img src="@/assets/images/sonos-logo.png" alt="SONOS" />
      <p>&copy; 2021 Sonos</p>
    </footer>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Bold.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Black.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-BoldItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-BlackItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Light.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Light.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk-HairlineItalic";
  src: url("assets/fonts/AktivGrotesk-HairlineItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-HairlineItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk-Hairline";
  src: url("assets/fonts/AktivGrotesk-Hairline.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Hairline.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-LightItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-LightItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Italic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Regular.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-ThinItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Thin.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk-XBoldItalic";
  src: url("assets/fonts/AktivGrotesk-XBoldItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-XBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-MediumItalic.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk-XBold";
  src: url("assets/fonts/AktivGrotesk-XBold.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-XBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AktivGrotesk";
  src: url("assets/fonts/AktivGrotesk-Medium.woff2") format("woff2"),
    url("assets/fonts/AktivGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
}
body {
  font-family: "AktivGrotesk";
}
h1 {
  font-weight: 500;
  color: white;
  font-size: 3.3em;
  line-height: 1;
  margin: 10px 0 20px 0;
}
h2 {
  font-weight: 500;
  color: white;
  font-size: 1.8em;
  line-height: 1.2;
  margin: 10px 0 10px 0;
}
h5 {
  color: #c0fd01;
  font-weight: 500;
  font-size: 1em;
}
p {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 0.95em;
  &.white {
    color: white;
  }
}
img {
  max-width: 100%;
}
body {
  background: #03350a;
}
#app {
  background: #bcd2a6;
  max-width: 1450px;
  margin: auto;
}
.content {
  max-width: 86%;
  margin: auto;
}
.btn {
  display: inline-block;
  border: 1px solid white;
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  font-size: 0.9em;
  color: white;
  .play-arrow {
    width: 12px;
    margin: 2px 10px 0 5px;
    transform: translateY(2px);
  }
  &:hover {
    background: white;
    color: #03350a;
  }
  &.active {
    background: #03350a;
    border-color: #03350a;
    &:hover {
      color: white;
    }
  }
}
footer {
  position: relative;
  z-index: 6;
}
.pre-footer {
  padding: 35px 20px 0 20px;
  background: #03350a;
  text-align: center;
  h4 {
    color: white;
    font-weight: 500;
    font-size: 0.9em;
  }
  .wrapper {
    max-width: 800px;
    margin: auto;
  }
  img {
    width: 80%;
    max-width: 50px;
    margin: 20px 0;
  }
}
.main-footer {
  background-color: #03350a;
  background-image: url(assets/images/footer.png);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;

  text-align: center;
  padding: 50px 0 0 0;
  min-height: 200px;
  color: white;
  p {
    margin-top: 15px;
    font-size: 0.6em;
  }
  img {
    max-width: 100px;
  }
  h5 {
    margin-bottom: 25px;
  }
  @media only screen and (min-width: 600px) {
    background-image: url(assets/images/footer-lg.png);
  }
}

* {
  margin: 0;
  padding: 0;
}

/* Icon 1 */
header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 20;
  transition: top 0.2s;
  .menu-wrapper {
    padding: 10px;
    @media only screen and (min-width: 600px) {
      position: absolute;
      width: 100%;
    }
  }
  .back-arrow {
    position: absolute;
    padding: 15px 10px;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 20px;
    display: none;
  }
  .subnav {
    padding-bottom: 8px;
    text-align: center;
    display: none;
    z-index: 16;
    position: relative;
    @media only screen and (min-width: 600px) {
      max-width: 500px;
      margin: auto;
    }
    .current-location {
      color: #bcd2a6;
      font-size: 10px;
      margin: 5px 0;
    }
    ul {
      display: none;
      list-style: none;
      li {
        display: block;
        width: calc(100% / 3);

        a {
          width: 80%;
          margin: auto;
          display: block;
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }

  &.submenu {
    background-color: #2a623a;
    background-size: cover;
    @media only screen and (max-width: 600px) {
      &.scrollmenu {
        top: -47px;
      }
    }
    .subnav {
      display: block;
      ul {
        display: flex;
      }
    }
    .back-arrow {
      display: block;
    }
    @media only screen and (min-width: 600px) {
      .back-arrow {
        display: block;
        width: 30px;
        top: 9px;
        left: 6px;
      }
    }
    .menu-wrapper {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
#menu {
  width: 30px;
  height: 25px;
  position: relative;
  margin: 0 0 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media only screen and (min-width: 600px) {
    margin: 15px 25px 12px auto;
  }
  &:hover {
    transform: scale(1.1, 1.1);
  }
}

#menu span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Icon 3 */

#menu span:nth-child(1) {
  top: 0px;
}

#menu span:nth-child(2),
#menu span:nth-child(3) {
  top: 10px;
}

#menu span:nth-child(4) {
  top: 20px;
}

#menu.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#menu.open span:nth-child(2) {
  transform: rotate(45deg);
}

#menu.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#menu.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  background: #03350a;
  background-image: url(assets/images/nav-footer.png);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  z-index: 19;
  left: -100vw;
  transition: left 0.5s;
  @media only screen and (min-width: 600px) {
    background-image: url(assets/images/nav-footer-lg.png);
    background-size: 90%;
    background-position: bottom left;
  }
  .main-nav-content {
    opacity: 0;
    transition: opacity 1s;
    .main-nav-wrap {
      margin-left: 50px;
      margin-top: 120px;
      @media only screen and (min-width: 600px) {
        margin-left: 30%;
        h5 {
          font-size: 1.5em;
        }
      }
      .lower {
        margin-top: 60px;
        p {
          font-size: 0.8em;
          margin-top: 4px;
          color: #4e7954;
          @media only screen and (min-width: 600px) {
            font-size: 0.9em;
            margin-top: 5px;
          }
        }
      }
    }
    ul {
      list-style: none;

      li {
        margin-bottom: 15px;
        font-weight: 500;
        a {
          color: white;
          opacity: 0.5;
          transition: opacity 0.2s;
          img {
            opacity: 0;
            width: 20px;
          }
          &:hover {
            opacity: 1;
            img {
              opacity: 1;
              width: 20px;
            }
          }
          display: block;
          font-size: 2em;
          @media only screen and (min-width: 600px) {
            font-size: 4em;
          }
          text-decoration: none;
        }
        @media only screen and (min-width: 600px) {
          margin-bottom: 5px;
        }
      }
    }
  }
  &.active {
    left: 0;
    .main-nav-content {
      opacity: 1;
    }
  }
  .nav-hero-top {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 90px;
  }
}
</style>


<script>
export default {
  name: "App",
  props: {},
  data() {
    return {
      showMenu: false,
      subMenu: false,
      currentTab: 1,
      scrollMenu: false,
      currentLocation: {},
    };
  },
  provide() {
    return {
      menuControls: {
        open: this.showSubnav,
        close: this.hideSubnav,
      },
      tabControls: {
        set: this.setActiveTab,
        getCurrentTab: () => {
          return this.currentTab;
        },
      },
      locationControls: {
        set: this.setCurrentLocation,
      },
    };
  },
  mounted: function () {
    this.handleScrollDirection();
  },
  methods: {
    showSubnav() {
      this.subMenu = true;
    },
    hideSubnav() {
      this.subMenu = false;
    },
    setActiveTab(index) {
      this.currentTab = index;
      window.scrollTo(0, 0);
      setTimeout(function () {
        /* Fix for parallax items not in position */
      }, 200);
    },
    setCurrentLocation(locationData) {
      this.currentLocation = locationData;
    },
    handleScrollDirection() {
      var lastScrollTop = 0;
      // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
      window.addEventListener(
        "scroll",
        () => {
          // or window.addEventListener("scroll"....
          var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
          if (st > lastScrollTop) {
            this.scrollMenu = true;
          } else {
            this.scrollMenu = false;
          }
          lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        },
        false
      );
    },
  },
};
</script>
