<template>
  <a class="link" href="#">
    <span class="link__arrow">
      <span></span>
      <span></span>
    </span>
    <span class="link__line"></span>
  </a>
</template>

<script>
export default {
  name: "DownArows",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.link {
  $self: &;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  transform: rotate(-90deg);
  margin-top: 25px;

  &__arrow {
    display: inline-flex;

    span {
      position: relative;
      width: 14px;
      height: 2px;
      border-radius: 2px;
      overflow: hidden;
      background: rgba(0, 0, 0, 0);
      z-index: 2;

      &:nth-child(1) {
        transform-origin: left bottom;
        transform: rotate(45deg) translate3d(8px, -10px, 0);
      }

      &:nth-child(2) {
        transform-origin: left bottom;
        transform: rotate(-45deg);
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
      }
    }
  }

  &__line {
    position: relative;
    margin-left: -14px;
    margin-right: 30px;
    width: 100px;
    height: 2px;
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 80px;
      width: 100px;
      height: 2px;
      background: #fff;
    }
  }

  &__text {
    color: #4a4a4a;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
  }

  #{$self} {
    &__line {
      &:after {
        animation: animation-line 1s forwards;
        animation-delay: 0.5s;
        left: 120px;
      }
    }

    &__arrow {
      span {
        &:after {
          animation: animation-arrow 1s forwards;
          animation-delay: 1.3s;
        }
      }
    }
  }
}

@keyframes animation-line {
  0% {
    left: 80px;
  }

  100% {
    left: 0;
  }
}

@keyframes animation-arrow {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
