<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Focean-sounds%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        Set in London’s vast Richmond Park is the Isabella Plantation. Filled
        with exotic plants, known for its 50 Japanese azaleas and run on organic
        principles, the name Isabella has been used for this site since the 17th
        century.
      </h3>
      <p>
        Richmond Park may be known as home to deer and other sorts of wildlife,
        but the Isabella Plantation is a 40 acre oasis of calm. Filled with
        ponds, plants and flowers, it’s a beautiful green spot for reflection
        and to take a moment of tranquility, taking quality time to free your
        spirit.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img src="@/assets/images/locations/05-isabella/body-1.png" alt="" />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Making the time to enjoy the sound around you is essential. Listening
        has a positive effect on our immediate connections to our environment
        and the emotional responses to that experience. Kalpana reflects on the
        importance of sound:
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Nature inspires calm. Natural spaces cultivate a sense of belonging"
      </div>
    </blockquote>
    <div class="content">
      <h2>Connection</h2>
      <p>
        Kalpana sees the Isabella Plantation as an ideal spot to encourage this
        connection, and nurture your inner calm: “Nature inspires calm. Natural
        spaces cultivate a sense of belonging in the world that is grounded in
        our being-ness. Here we get to explore who we are, our individual and
        collective identities, and what our role is on the planet.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/locations/05-isabella/body-2.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              Richmond Park is London’s largest site of scientific interest.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content" style="padding-top: 50px">
      <p>
        The Isabella Plantation is open to everyone, and Kalpana sees the
        importance in green spaces being all-access and free for all: “Access to
        green spaces for everyone is an important catalyst for individual
        transformation that facilitates a reorientation back to ourselves, our
        community, and the earth. Green spaces in urban areas support our
        wellbeing and build climate resilience in fast changing landscapes.”
          </p>

        <p>
          <a href="https://tfl.gov.uk/modes/walking/capital-ring" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/05-isabella/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/05-isabella/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/05-isabella/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/05-isabella/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Isabella",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>