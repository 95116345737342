<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fsession-sounds%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        Ellen, from Nature is a Human Right, describes the feeling of
        discovering Crystal Palace perfectly: “Going to Crystal Palace makes me
        feel like a kid again.” The park was designed as a pleasure ground in
        the Victorian times, and was home to The Crystal Palace, which was sadly
        destroyed in 1936.
      </h3>
      <p>
        Its 200 acres of green space make it an ideal spot to meet and explore,
        with plenty of surprises for young and old alike. There are 30 life-size
        dinosaur statues to be found, and a maze to get lost in.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/07-crystalpalace/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        There’s also an amphitheatre, but if you’re not lucky enough to catch a
        show, then you can create your own ambience with Sonos Roam. Music helps
        you connect with your environment, and your friends and family, and
        Crystal Palace is an ideal spot for groups to gather and enjoy music
        together.
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Being in green space brings people together"
      </div>
    </blockquote>
    <div class="content">
      <h2>Human Interaction</h2>
      <p>
        Ellen thinks nature has the same qualities in encouraging human
        interaction: “Being in green space brings people together. Places with
        natural features provide settings in which relationships grow stronger”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/07-crystalpalace/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              The Crystal Palace was originally built in Hyde Park in 1851, and
              only moved to South London in 1854, after the Great Exhibition.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        As people have become more used to living online, and have been forced
        into either small groups or even solo life by the recent lockdowns, can
        fun green spaces like Crystal Palace play a role in bringing people back
        together? Ellen thinks so: “Part of the problem now is that we treat
        nature like a museum rather than something to interact with, or a stage
        for life. By showing people that green spaces can be places to play,
        eat, dance, paint, read, write and hang out we can start to make
        everyone feel at home in nature, and so encourage them to spend more
        time there.”
          </p>
          <p>
            <a href="https://tfl.gov.uk/modes/walking/green-chain-walk" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
          </p>
        <img
              src="@/assets/images/locations/07-crystalpalace/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/07-crystalpalace/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/07-crystalpalace/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/07-crystalpalace/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CrystalPalace",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>