<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fradio-chemical-curated-by-the-chemical-brothers%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        No question, Osterley Park is a haven for outdoor activities. On any
        given day you’ll find it filled with people out for a run, a cycle, or
        even a gentle paddle in a canoe. It’s a space where you’ll see
        first-hand both the importance of outdoor exercise, and the variety it
        offers.
      </h3>
      <p>
        At the heart of the park is a stately Georgian mansion that has stood on
        the site since 1761. The National Trust property has a Grade 1 listed
        status, and the red brick mansion makes for a unique backdrop as you
        run, cycle or skate by.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/11-osterleypark/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        While the house comes with an entry fee, the park is free for everyone
        to enjoy. From a refreshing walk, to something a bit more strenuous,
        those seeking a green space for some outdoor exercise can find a spot
        here. Ayisha feels this open availability is important: “A great benefit
        to exercising outdoors is that it’s free. It’s accessible to everyone
        which I think is great.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Music is extremely important to me when exercising"
      </div>
    </blockquote>
    <div class="content">
      <h2>Music &amp; Exercise</h2>
      <p>
        Osterley Park offers up the ideal space to enjoy some music while you
        exercise. A Sonos survey shows that over two thirds of people in the UK
        credit music with making their best fitness performances possible, and
        the Sonos Roam allows you to keep upping your game with quality sound
        while working-out outdoors.
      </p>
      <p>
        Ayisha knows the importance of music for her skating: “Music is
        extremely important to me when exercising/skating as it keeps me going.
        For me it’s more dancing to the music than exercising so because I’m
        enjoying it more, I’m burning more calories without even realising it.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/11-osterleypark/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              The interior of Osterley Park house was used as the interior of
              Wayne Manor in 2012’s The Dark Knight Rises.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        The vast grounds of Osterley Park mean everyone can feel comfortable as
        they enjoy their exercise, something Ayisha hasn’t found everywhere.
        “When there’s a safe open space surrounded by nature we’re the happiest
        people in the world!”
          </p>
             <p>
          <a href="https://tfl.gov.uk/modes/walking/capital-ring" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/11-osterleypark/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/11-osterleypark/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/11-osterleypark/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/11-osterleypark/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OsterleyPark",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>