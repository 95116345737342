<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fpindrop-electronic%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        Does a green space by the water, surrounded by birds and animals sound
        like the perfect spot for some exercise to you? Then look no further
        than Woodberry Wetlands. After becoming a nature reserve in 2014,
        Woodberry Wetlands was opened in 2016 by Sir David Attenborough, the
        first time it had been open to the public in over 200 years.
      </h3>
      <p>
        The space is a green haven in the middle of London’s dense, built-up
        Stoke Newington. Just a five minute walk from Manor House station, the
        Wetlands offers such a contrast to its urban surroundings.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/12-woodberrywetlands/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Migratory birds visit, and wildflowers grow along the hedges, making
        Woodberry a very peaceful spot to sit and relax, and have a break in the
        middle of your exercise. Ayisha enjoys the opportunity for calm in the
        middle of a skating session, a time to relax and enjoy some music and
        take in the sounds around her: “Green spaces give me an opportunity to
        connect with myself and be at one, when I want to take a break from
        skating.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Green spaces give me an opportunity to connect with myself"
      </div>
    </blockquote>
    <div class="content">
      <h2>Exercising</h2>
      <p>
        Whether exercising or relaxing, listening is intrinsic to lifting our
        spirits and feeling better, and the ultraportable Sonos Roam allows you
        to enjoy quality sound, wherever your adventure takes you.
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/12-woodberrywetlands/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              The New River, which the Wetlands lies near, was built in 1604 to
              bring water to London from Hertfordshire.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        After a period of restrictions, Woodberry Wetlands is open again to
        runners and joggers. With the water by your side and the nature around,
        it’s a great spot to get lost in your playlist as you move, or take time
        with your own thoughts.
          </p>
           <p>
          <a href="https://tfl.gov.uk/modes/walking/capital-ring" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/12-woodberrywetlands/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/12-woodberrywetlands/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/12-woodberrywetlands/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/12-woodberrywetlands/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WoodberryWetlands",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>