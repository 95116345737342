<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fbritish-beats-bars%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      <h3>
        Perhaps better known for the prison that shares its name, Wormwood
        Scrubs is one of the largest green spaces in the London Borough of
        Hammersmith and Fulham. The area was once a forest, but the trees on the
        Scrubs were felled, leaving soil that was only fit for grazing.
      </h3>
      <p>
        What’s left is a wonderful open green space, ideal for gathering people
        together for walks, games and sports. As the world seemed to become
        smaller last year, wide outdoor spaces have taken on a new significance,
        and Wormwood Scrubs is a place that could have been tailor-made for
        outdoor fun.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img src="@/assets/images/locations/09-wormwood/body-1.png" alt="" />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        An open space like Wormwood Scrubs can be transformed to whatever you’d
        like it to be. Ellen sees music as an essential part of this: “Music can
        change the way you experience a green space. Whether you’re there to
        relax and unwind, play, work out or hang out with people, music can help
        a green space match your mood.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Music can change the way you experience a green space"
      </div>
    </blockquote>
    <div class="content">
      <h2>Gathering</h2>
      <p>
        There’s plenty of space to gather friends, sit in a group, enjoy some
        music and each other’s company. A
        <a
          href="https://blog.sonos.com/en-gb/brilliant-sound-survey"
          target="_blank"
          >Sonos survey</a
        >
        showed that 68% say music makes them feel closer to the people around
        them. Wormwood Scrubs is the ideal spot to try out the quality sound of
        the ultraportable Sonos Roam, and create the ideal atmosphere.
      </p>
      <p>
        Ellen sees the fresh air as removing some of the barriers to social
        interaction: “Nature makes people less self-focused, prioritising values
        like connection, contribution and community.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/locations/09-wormwood/body-2.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              Wormwood Scrubs was home to Queens Park Rangers FC between 1893
              and 1896.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        So grab your friends, family and take some time to wander around
        Wormwood Scrubs, taking in the sounds around you as you enjoy the wide
        open space.
          </p>

           <p>
          <a href="https://tfl.gov.uk/modes/walking/jubilee-greenway" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/09-wormwood/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/09-wormwood/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/09-wormwood/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/09-wormwood/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Wormwood",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>