<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fahead-of-their-time-feat-mykki-blanco%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        Gardening can be a relaxing, calming experience. The idea of tending,
        caring and nurturing can help focus and to bring a bit of peace into a
        hectic existence.
      </h3>
      <p>
        It’s also a route to a more sustainable future, which is what Gaia’s
        Garden, set in the shadow of St Paul’s cathedral, is all about.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/15-gaiasgarden/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Built with the help of 200 volunteers, Gaia’s Garden was devised by five
        young female creatives, and aims to present sustainability through a
        more inclusive lens. Gaia’s Garden provides a space for the local
        community to get involved in both the nurturing of green spaces, but
        also to meet their neighbours and develop a greater community spirit.
        Recently opened, the garden is at an early stage, but will be host to
        workshops and performances as it starts to flourish.
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Cultivating our local green spaces brings people together"
      </div>
    </blockquote>
    <div class="content">
      <h2>Community</h2>
      <p>
        Jess from Water Daily understands the feeling of community that comes
        from working together: “Cultivating our local green spaces helps bring
        communities and people back together, which is even more important after
        months of being cut off from each other. I met such an amazing group of
        like minded people, and hopefully made some friends for life!”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/15-gaiasgarden/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              In Greek mythology, Gaia, who lends her name to Gaia’s Garden, is
              the personification of the Earth.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        So, keep an eye out for your local community gardening project. It may
        be even more rewarding than you imagine. Jess certainly feels the
        benefits: “The act of gardening is super therapeutic, rewarding and fun.
        So we need to nurture it.”
      </p>
      <p>
        For further information on Gaia’s Garden, please visit:
        <a href="https://www.gaiasgarden.london/about-us" target="_blank"
          >https://www.gaiasgarden.london/about-us</a
        >
        </p>
        <p>
          <a href="https://tfl.gov.uk/modes/walking/jubilee-walkway" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/15-gaiasgarden/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/15-gaiasgarden/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/15-gaiasgarden/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/15-gaiasgarden/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GaiasGarden",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>