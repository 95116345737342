<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fquiet-classical%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        London is home to a wealth of green spaces, many famous for their
        wildlife. There’s only one, however, that can boast of having an
        oversized Walrus on its grounds...
      </h3>
      <p>
        The Horniman Museum and Garden is a beautiful spot in South London, with
        a stunning view of the city. It’s a great place to relax, enjoy the
        sounds surrounding you. It’s also a great place to learn about the
        biodiversity and nature that’s on your doorstep.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/01-horniman-museum/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
    <div class="content content-break">
      <p>
        Horticulturalist Hafsah Hafeji discusses the need for green spaces in
        education: “We need more green spaces for people to learn and implement
        this learning into. Creating green spaces in urban areas where most of
        these habitats have been destroyed can have a huge impact on supporting
        local biodiversity as well as become a place for people to engage and
        connect with the natural world.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "We need more green spaces for people to learn and implement"
      </div>
    </blockquote>
    <div class="content">
      <h2>Education</h2>
      <p>
        The Horniman is dedicated to education about the natural world. Hafsah
        continues: “The Horniman has pledged to increase the number and variety
        of planting schemes in their gardens to promote biodiversity, which is a
        great example of forward-thinking.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/01-horniman-museum/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              The ‘fat Walrus’ that the Horniman is famous for was stuffed over
              100 years ago.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">

      <p>
        Education about nature has become particularly important recently, as
        people crave the fresh air and freedom that the outdoors provides.
        Hafsah believes that the new interest should lead to more support for
        green spaces: “I would really encourage people to support these spaces,
        learn from what they are doing, share this knowledge and put it into
        practice in your own garden or in a local space.”
      </p>
          <p>
          <a href="https://tfl.gov.uk/modes/walking/green-chain-walk" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
      <img
            src="@/assets/images/locations/01-horniman-museum/body-2-lg.png"
            alt=""
            class="desktop-picture"
          />
    </div>
    <div class="base-images">
  
           <img
            src="@/assets/images/locations/01-horniman-museum/body-3-lg.png"
            alt=""
          />
    </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/01-horniman-museum/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/01-horniman-museum/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HornimanMuseum",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>