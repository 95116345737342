<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fthe-inner-now%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        As you wander through the spacious green surroundings of Holland Park,
        you might need a double-take when you first see the Kyoto Garden. With
        tiered waterfalls, stone lanterns and maple trees, you could be forgiven
        for thinking that you’d somehow found yourself in Japan.
      </h3>
      <p>
        The Kyoto Garden opened in 1991, and comes complete with an ornamental
        koi pond. You’ll even spot a peacock if you’re lucky. The soothing
        sounds of water make it one of the most tranquil spots in London,
        perfect for taking a calm moment with your thoughts. There’s no better
        place for a peaceful rest, taking time to appreciate the sounds around
        you.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img src="@/assets/images/locations/04-kyoto/body-1.png" alt="" />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Have we lost sight of the importance of this kind of calm in the 21st
        century? Yoga and meditation teacher, Kalpana Arias, believes that the
        opportunity for calm still exists, even in busy cities: “Our culture has
        conditioned us to be subject to the many distractions of the mind. But
        calm is present, always waiting for us to arrive home to our bodies and
        the earth.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Nature helps us to lift the veils of perception by encouraging us to
        experience felt sensations"
      </div>
    </blockquote>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/locations/04-kyoto/body-2.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              The Kyoto Garden was a gift from the city of Kyoto to commemorate
              the long friendship between Japan and Great Britain.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        She sees the kind of nature that you can find in Holland Park, and the
        Kyoto Garden, as essential in grounding us: “Nature helps us to lift the
        veils of perception by encouraging us to experience felt sensations that
        bring us back into our bodies and into the present moment, where we
        sense stillness as a ‘calm’ quality in our body-mind.”
          </p>
        <p>
          <a href="https://tfl.gov.uk/modes/walking/jubilee-greenway" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/04-kyoto/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/04-kyoto/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/04-kyoto/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/04-kyoto/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Kyoto",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>