<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fmore-minimal%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        If you like a walk with a view, then Hilly Fields is an ideal spot for
        you. A lovely green spot, with tree-lined pathways and one of the best
        views of the City in South London, this little hideaway in Lewisham is
        perfect for taking a breath and reflecting while appreciating the vista.
      </h3>
      <p>
        It can be hard to find a space to really make time for yourself.
        Especially in a busy, always-on digital age, it can be tricky to take a
        moment to breathe and enjoy a little bit of time in nature. Whether it’s
        looking over the City, or sitting among Hilly Fields’ stone circle,
        Hilly Fields affords you the space to reflect, and bathe in the sounds,
        enjoying the gift of quality time.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/06-hillyfields/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        So, what does it take to really appreciate even a fleeting moment of
        relaxation in a place like Hilly Fields. Yoga and meditation expert
        Kalpana has a few tips: “Meditating in nature is an accessible practice
        within itself, so I would encourage you to drop into the sensory
        experience of your chosen outdoor space.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Meditating in nature is an accessible practice within itself"
      </div>
    </blockquote>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/06-hillyfields/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              Hilly Fields’ stone circle was built to mark the Millennium in
              2000.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content" style="padding-top: 50px">
      <p>
        It’s about focussing on a sight, like Hilly Fields’ view, the smell of
        the grass or the sounds around you. Kalpana continues: “If you need
        support connecting to your senses, you can choose to focus on a
        particular sense whether sight, smell, touch, sound or taste, to
        stimulate and heighten its experience through focus.”
          </p>
           <p>
          <a href="https://tfl.gov.uk/modes/walking/green-chain-walk" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/06-hillyfields/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/06-hillyfields/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/06-hillyfields/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/06-hillyfields/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HillyFields",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>