<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fchill-beats%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        The mightiest oaks come from even the smallest seeds, and a new
        community garden, Boiler Garden is hoping to grow and grow.
      </h3>
      <p>
        Set in the Myatt’s Field South Estate, between Brixton Road and
        Camberwell Road in South London, the garden hopes to bring the community
        together in a new biodiversity project.
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/14-boilergarden/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <p>
        Jess, of Water Daily, thinks access to nature is something that should
        be available to everyone, and is an essential aspect of wellbeing: “For
        most people living in London, having access to a garden is a luxury, so
        creating green spaces that everyone can enjoy is essential. Giving
        everyone equal access to green spaces would democratise gardening for
        good.”
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Music can help encourage feelings of peace, transcendence and
        tenderness"
      </div>
    </blockquote>
    <div class="content">
      <h2>Gardening</h2>
      <p>
        The idea of gardening is a romantic one. Music can help encourage
        feelings of peace, transcendence and tenderness, providing the perfect
        backdrop to an afternoon in the garden. Theo enjoys gardening to music
        for an even more peaceful experience: For me, music and gardening have a
        similar impact on me in terms of switching off and getting lost in my
        thoughts. Combining the two, I get to completely immerse myself in the
        present in a way that is very private and personal to me.”
      </p>
      <p>
        Theo, of Water Daily, thinks it’s time to reclaim gardening’s
        reputation: “Gardening has a history steeped in revolution and
        reclaiming the land, but the wider horticultural industry today is very
        stuffy. I think there is so much room for a fresh approach to gardening
        that appeals to the wave of Millennial and Generation Z growers that
        have discovered gardening during lockdown.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/14-boilergarden/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              London has the lowest percentage of houses with gardens in the UK.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content" style="padding-top: 50px">
      <p>
        Can projects like Boiler Garden bring communities back to gardening?
        Theo continues: “I think it’s about creating sustainable alternatives
        and breaking down the barriers that can make gardening feel quite
        complicated and overwhelming.”
      </p>
      <p>
        For further information on the Boiler Garden, please visit their
        Instagram:
        <a href="https://www.instagram.com/friendsofmfs/" target="_blank"
          >@_friendsofmfs</a
        >
         </p>

          <p>
            <a href="https://tfl.gov.uk/modes/walking/thames-path" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
          </p>
        <img
              src="@/assets/images/locations/14-boilergarden/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/14-boilergarden/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/14-boilergarden/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/14-boilergarden/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoilerGarden",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>