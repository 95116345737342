<template>
  <div class="home">
    <div class="hero">
      <div class="hero-mobile">
        <img
          src="../assets/images/home-hero-photo.jpg"
          alt=""
          v-prlx.mobile="{ reverse: true }"
          class="prlx-img"
        />
        <img
          src="../assets/images/home-hero-pattern-top.png"
          class="top-banner prlx-img"
        />
        <img
          src="../assets/images/home-hero-pattern-bottom.png"
          alt=""
          class="prlx-img prlx-img-ptn-btm"
          v-prlx.mobile="{ limit: { min: -30, max: 30 } }"
        />
      </div>
      <img src="../assets/images/home-hero-bg-lg.png" class="desktop-header" />
      <div class="home-text-wrapper">
        <div
          class="hero-text content"
          v-prlx.mobile="{ limit: { min: -30, max: 100 } }"
        >
          <h5>#RoamAndExplore</h5>
          <h1>Roam<br />and<br />Explore</h1>
          <div style="margin-left: -60px">
            <DownArrow />
          </div>
        </div>
        <div
          class="sponsors content"
          v-prlx.mobile="{ limit: { min: -100, max: 20 }, reverse: true }"
        >
          <img src="../assets/images/sonos-logo.png" alt="SONOS" /><br />
        </div>
      </div>
      <div class="hero-sub-content content">
        <h2>Your guide to a greener life in London</h2>
        <p data-aos="fade-up" data-aos-duration="1000">
          At a time when nature can feel further away than ever, how do we
          rekindle our love of the outdoors? By exploring. By discovering the
          joy of the green spaces, and taking in new sights and sounds as we
          connect with our local environment.
        </p>
        <p data-aos="fade-up" data-aos-duration="1000">
          To inspire you, Sonos have created Roam and Explore, a walking guide
          to London. You’ll learn about some very special green spaces across
          the capital, how best to use them, and hear what makes the outdoors so
          important for health and wellbeing.
        </p>
        <p data-aos="fade-up" data-aos-duration="1000" class="white">
          So let us be your guide, and let the new Sonos Roam provide the ideal
          soundtrack, as you discover your own green corner of the big city.
        </p>
      </div>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }">
        <picture>
          <source
            srcset="../assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="../assets/images/home-1.png" alt="" />
        </picture>
      </div>
      <div data-aos="fade-up" data-aos-offset="100" data-aos-duration="2000">
        <h3>
          <span>Nature Is A Human Right.</span> Discover green spaces
          <div style="margin-top: 20px; margin-left: -60px">
            <DownArrow />
          </div>
        </h3>
      </div>
    </div>
    <!--
        MODAL FOR PREVIEWING LOCATION
    -->
    <div
      class="modalBackdrop"
      v-bind:class="{ active: selectedLocation > 0 }"
      v-on:click="selectedLocation = 0"
    ></div>
    <div
      class="locationPreview"
      v-bind:class="{ active: selectedLocation > 0 }"
    >
      <img
        :src="getImgUrl(selectedLocationData.slug)"
        class="bg-image"
        v-if="selectedLocationData"
      />
      <div class="modal" v-if="selectedLocationData">
        <p class="modal-close" v-on:click="selectedLocation = 0">
          <img src="../assets/images/right-arrow.svg" class="arrow" /> Close
        </p>
        <h2>
          <span>{{ selectedLocationData.category }}</span
          ><br />{{ selectedLocationData.title }}
        </h2>
        <p>
          {{ selectedLocationData.description }}
        </p>
        <p style="text-align: right">
          <router-link
            :to="{
              name: 'Location',
              params: { slug: selectedLocationData.slug },
            }"
            class="btn"
            >Explore</router-link
          >
        </p>
      </div>
    </div>
    <!--
       MAP
    -->
    <div class="map" id="mapBox">
      <ul class="progress-indicator">
        <li
          class="indicator-category-1"
          v-bind:class="{ active: currentCategory === 1 }"
        ></li>
        <li
          class="indicator-category-2"
          v-bind:class="{ active: currentCategory === 2 }"
        ></li>
        <li
          class="indicator-category-3"
          v-bind:class="{ active: currentCategory === 3 }"
        ></li>
        <li
          class="indicator-category-4"
          v-bind:class="{ active: currentCategory === 4 }"
        ></li>
        <li
          class="indicator-category-5"
          v-bind:class="{ active: currentCategory === 5 }"
        ></li>
      </ul>
      <div class="map-content">
        <div class="map-graphic">
          <img src="../assets/images/map-background.svg" />
          <div class="map-pins">

            <span
              class="pin pin-7 pin-category-1"
              v-bind:class="{ active: currentCategory === 1 }"
              v-on:click="selectedLocation = 7"
              ><i>01</i></span
            >
            <span
              class="pin pin-8 pin-category-1"
              v-bind:class="{ active: currentCategory === 1 }"
              v-on:click="selectedLocation = 8"
              ><i>02</i></span
            >
            <span
              class="pin pin-9 pin-category-1"
              v-bind:class="{ active: currentCategory === 1 }"
              v-on:click="selectedLocation = 9"
              ><i>03</i></span
            >

            <span
              class="pin pin-10 pin-category-2"
              v-bind:class="{ active: currentCategory === 2 }"
              v-on:click="selectedLocation = 10"
              ><i>04</i></span
            >
            <span
              class="pin pin-11 pin-category-2"
              v-bind:class="{ active: currentCategory === 2 }"
              v-on:click="selectedLocation = 11"
              ><i>05</i></span
            >
            <span
              class="pin pin-12 pin-category-2"
              v-bind:class="{ active: currentCategory === 2 }"
              v-on:click="selectedLocation = 12"
              ><i>06</i></span
            >

              <span
              class="pin pin-4 pin-category-3"
              v-bind:class="{ active: currentCategory === 3 }"
              v-on:click="selectedLocation = 4"
              ><i>07</i></span
            >
            <span
              class="pin pin-5 pin-category-3"
              v-bind:class="{ active: currentCategory === 3 }"
              v-on:click="selectedLocation = 5"
              ><i>08</i></span
            >
            <span
              class="pin pin-6 pin-category-3"
              v-bind:class="{ active: currentCategory === 3 }"
              v-on:click="selectedLocation = 6"
              ><i>09</i></span
            >
            <span
              class="pin pin-1 pin-category-4"
              v-bind:class="{ active: currentCategory === 4 }"
              v-on:click="selectedLocation = 1"
              ><i>10</i></span
            >
            <span
              class="pin pin-2 pin-category-4"
              v-bind:class="{ active: currentCategory === 4 }"
              v-on:click="selectedLocation = 2"
              ><i>11</i></span
            >
            <span
              class="pin pin-3 pin-category-4"
              v-bind:class="{ active: currentCategory === 4 }"
              v-on:click="selectedLocation = 3"
              ><i>12</i></span
            >
            <span
              class="pin pin-13 pin-category-5"
              v-bind:class="{ active: currentCategory === 5 }"
              v-on:click="selectedLocation = 13"
              ><i>13</i></span
            >
            <span
              class="pin pin-14 pin-category-5"
              v-bind:class="{ active: currentCategory === 5 }"
              v-on:click="selectedLocation = 14"
              ><i>14</i></span
            >
            <span
              class="pin pin-15 pin-category-5"
              v-bind:class="{ active: currentCategory === 5 }"
              v-on:click="selectedLocation = 15"
              ><i>15</i></span
            >
          </div>
        </div>
        <div class="map-content-inner">
          <div class="map-spacer"></div>
            <div class="map-category map-category-1" data-category-id="1">
            <h2 v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }">
              Gathering
            </h2>
            <ul v-prlx.mobile="{ reverse: false }" class="pin-category-1">
              <li v-on:click="selectedLocation = 7">
                <span>01</span> Crystal Palace Park
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 8">
                <span>02</span> Golders Hill Park
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 9">
                <span>03</span> Wormwood Scrubs
                <a>more +</a>
              </li>
            </ul>
          </div>
          <div class="map-category map-category-2" data-category-id="2">
            <h2 v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }">
              Exercise
            </h2>
            <ul v-prlx.mobile="{ reverse: false }" class="pin-category-2">
              <li v-on:click="selectedLocation = 10">
                <span>04</span> North Greenwich Riverside
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 11">
                <span>05</span> Osterley Park
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 12">
                <span>06</span> Woodberry Wetlands
                <a>more +</a>
              </li>
            </ul>
          </div>

         <div class="map-category map-category-3" data-category-id="3">
            <h2 v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }">
              Meditating
            </h2>
            <ul v-prlx.mobile="{ reverse: false }" class="pin-category-3">
              <li v-on:click="selectedLocation = 4">
                <span>07</span> Kyoto Garden
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 5">
                <span>08</span> Isabella Plantation
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 6">
                <span>09</span> Hilly Fields
                <a>more +</a>
              </li>
            </ul>
          </div>

          <div class="map-category map-category-4" data-category-id="4">
            <h2 v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }">
              Learning
            </h2>
            <ul v-prlx.mobile="{ reverse: false }" class="pin-category-4">
              <li v-on:click="selectedLocation = 1">
                <span>10</span> Horniman Museum &amp; Garden
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 2">
                <span>11</span> Barbican Conservatory
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 3">
                <span>12</span> Culpeper Community Garden
                <a>more +</a>
              </li>
            </ul>
          </div>

          <div class="map-category map-category-5" data-category-id="5">
            <h2 v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }">
              Volunteering
            </h2>
            <ul v-prlx.mobile="{ reverse: false }" class="pin-category-5">
              <li v-on:click="selectedLocation = 13">
                <span>13</span> The Haggerston Orchard
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 14">
                <span>14</span> The Boiler Garden
                <a>more +</a>
              </li>
              <li v-on:click="selectedLocation = 15">
                <span>15</span> Gaia's Garden .
                <a>more +</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="about">
      <div>
        <h5 data-aos="fade-up" data-aos-offset="150">#RoamandExplore</h5>
        <h2 data-aos="fade-up" data-aos-offset="150">About Roam and Explore</h2>
        <p data-aos="fade-up" data-aos-offset="150">
          To inspire you to make the most of these moments, Sonos have created
          Roam and Explore, a walking guide to London. With over 8.4m trees, the
          city has a wealth of green spaces. Our walks connect destinations from
          the heart of London, stretching out to the edges of the city.
        </p>
        <p style="text-align: right">
          <router-link to="/about" class="btn">Find out more</router-link>
        </p>
      </div>
    </div>
    <footer class="pre-footer">
      <div class="wrapper">
        <h4>Our Partners</h4>
        <div class="partners-grid">
          <router-link to="/partners">
            <img
              src="@/assets/images/partner-nhr.png"
              alt="Nature is a human right"
            />
          </router-link>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.home {
  .hero {
    .prlx-img {
      position: absolute;
      top: 0;
      img {
        width: 100%;
      }
      &.top-banner {
        right: 0;
        max-width: 110px;
      }
      &.prlx-img-ptn-btm {
        top: 50px;
      }
    }
    .hero-text {
      z-index: 5;
      margin-top: 12vh;
    }
    .sponsors {
      z-index: 5;
      position: relative;
      text-align: right;
      margin-top: 100px;
      img {
        margin-bottom: 8px;
        width: 85px;
      }
    }
    .hero-sub-content {
      z-index: 5;
      position: relative;
      margin-top: 80px;
      color: #4e7954;
      h2 {
        max-width: 80%;
      }
    }
    .desktop-header {
      display: none;
    }
    @media only screen and (min-width: 800px) {
      .desktop-header {
        display: block;
      }
      .hero-mobile {
        display: none;
      }
      .link {
        display: none;
      }
      .home-text-wrapper {
        position: absolute;
        top: 150px;
        margin-left: 150px;
      }
      .hero-text {
        margin-top: 0;

        h1 {
          font-size: 4em;
        }
      }
      .hero-sub-content {
        width: 25%;
        margin-right: 8%;
      }
      .sponsors {
        text-align: left;
        margin-top: 150px;
        transform: none !important;
        img {
          width: 120px;
          max-width: 250px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .tile {
    position: relative;
    max-width: 600px;
    margin: auto;
    z-index: 6;

    h3 {
      margin: -50% auto 0 auto;
      max-width: 240px;
      font-size: 2em;
      color: white;
      font-weight: 500;
      z-index: 5;
      span {
        color: #bcd2a6;
      }
    }
    @media only screen and (min-width: 800px) {
      transform: translateX(-30%) translateY(-140%);
      h3 {
        margin: -50% auto 0 auto;
      }
    }
  }
  .map {
    max-width: 80%;
    margin: 200px auto 100px auto;
    text-align: center;
    .progress-indicator {
      position: fixed;
      left: 0;
      top: 26%;
      opacity: 0;
      transition: opacity 0.5s ease-out;
      list-style: none;
      li {
        background-color: rgba(255, 255, 255, 0.5);
        height: 35px;
        width: 7px;
        margin-bottom: 2px;
        transition: background-color 0.2s;
        &.indicator-category-1.active {
          background-color: #50a8a1;
        }
        &.indicator-category-2.active {
          background-color: #439079;
        }
        &.indicator-category-3.active {
          background-color: #387a57;
        }
        &.indicator-category-4.active {
          background-color: #2a623a;
        }
        &.indicator-category-5.active {
          background-color: #1f4a21;
        }
      }
      @media only screen and (min-width: 800px) {
        left: 25%;
        bottom: 0;
        top: auto;
        display: flex;
        width: 50%;
        li {
          width: 20%;
          height: 10px;
        }
      }
    }
    .map-content {
      position: relative;
      .map-graphic {
        position: fixed;
        top: 20vh;
        left: 0;
        width: 100%;
        text-align: center;
        img {
          width: 90%;
          opacity: 0;
          transition: opacity 0.5s;
        }
        .map-pins {
          transition: opacity 0.5s;
          transition-delay: 0s;
          opacity: 0;
        }
        .pin {
          position: absolute;
          display: block;
          transform: scale(0.5, 0.5);
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          box-shadow: 0px 0px 15px 2px rgba(255, 255, 255, 0.75);
          border-radius: 50px;
          background: white;
          transition: all 0.4s;
          animation: pulse-white 2s infinite;
          i {
            font-size: 8px;
            transform: scale(0, 0);
            transition: opacity 0.2s;
            transition-delay: 0.2s;
            color: white;
            display: block;
            font-weight: 600 !important;
            font-style: normal !important;
          }
          &.active {
            background: #4e7954;
            transform: scale(1.3, 1.3);
            z-index: 10;
            i {
              transform: scale(1, 1) translateY(4.5px) translateX(0px);
            }
          }
          &.pin-category-1.active {
            background: #50a8a1;
          }
          &.pin-category-2.active {
            background: #439079;
          }
          &.pin-category-3.active {
            background: #387a57;
          }
          &.pin-category-4.active {
            background: #2a623a;
          }
          &.pin-category-5.active {
            background: #1f4a21;
          }
          &.pin-1 {
            top: 71%;
            left: 55%;
            &.active {
              transition-delay: 0.2s;
            }
          }
          &.pin-2 {
            top: 30%;
            left: 49%;
            &.active {
              transition-delay: 0.4s;
            }
          }
          &.pin-3 {
            top: 21%;
            left: 45%;
            &.active {
              transition-delay: 0.6s;
            }
          }
          &.pin-4 {
            top: 35%;
            left: 32%;
            &.active {
              transition-delay: 0.2s;
            }
          }
          &.pin-5 {
            top: 56%;
            left: 31%;
            &.active {
              transition-delay: 0.4s;
            }
          }
          &.pin-6 {
            top: 61%;
            left: 56%;
            &.active {
              transition-delay: 0.6s;
            }
          }
          &.pin-7 {
            top: 75%;
            left: 48%;
            &.active {
              transition-delay: 0.2s;
            }
          }
          &.pin-8 {
            top: 15%;
            left: 38%;
            &.active {
              transition-delay: 0.4s;
            }
          }
          &.pin-9 {
            top: 28%;
            left: 26%;
            &.active {
              transition-delay: 0.6s;
            }
          }
          &.pin-10 {
            top: 47%;
            left: 64%;
            &.active {
              transition-delay: 0.2s;
            }
          }
          &.pin-11 {
            top: 35%;
            left: 22%;
            &.active {
              transition-delay: 0.4s;
            }
          }
          &.pin-12 {
            top: 19%;
            left: 50%;
            &.active {
              transition-delay: 0.6s;
            }
          }
          &.pin-13 {
            top: 23%;
            left: 50%;
            &.active {
              transition-delay: 0.2s;
            }
          }
          &.pin-14 {
            top: 60%;
            left: 47%;
            &.active {
              transition-delay: 0.4s;
            }
          }
          &.pin-15 {
            top: 34%;
            left: 47%;
            &.active {
              transition-delay: 0.6s;
            }
          }
        }
      }
      .map-content-inner {
        position: relative;
        z-index: 10;
      }
      .map-spacer {
        height: 80vh;
        @media only screen and (min-width: 800px) {
          height:40vh;
        }
      }
      .map-category {
        height: 100vh;
        margin-left: -50%;
        h2 {
          color: #387a57;
          margin-bottom: 15px;
        }
        &.map-category-1 h2 {
          color: #50a8a1;
        }
        &.map-category-2 h2 {
          color: #439079;
        }
        &.map-category-3 h2 {
          color: #387a57;
        }
        &.map-category-4 h2 {
          color: #2a623a;
        }
        &.map-category-5 h2 {
          color: #1f4a21;
        }
        ul {
          list-style: none;
          margin-left: 40%;
          text-align: left;
          li {
            color: white;
            margin-bottom: 10px;
            cursor: pointer;
            font-size: 0.9em;
            a {
              opacity: 0.5;
              text-decoration: underline;
            }
            .arrow {
              width: 15px;
              margin-left: 0;
              transition: margin 0.2s;
            }
            &:hover {
              .arrow {
                margin-left: 15px;
              }
            }
            span {
              background: #387a57;
              padding: 5px;
              font-size: 10px;
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 20px;
              transform: translateY(-2px);
              font-weight: 600;
              margin-right: 5px;
            }
          }
          &.pin-category-1 {
            span {
              background: #50a8a1;
            }
            a {
              color: #50a8a1;
            }
          }
          &.pin-category-2 {
            span {
              background: #439079;
            }
            a {
              color: #439079;
            }
          }
          &.pin-category-3 {
            span {
              background: #387a57;
            }
            a {
              color: #387a57;
            }
          }
          &.pin-category-4 {
            span {
              background: #2a623a;
            }
            a {
              color: #2a623a;
            }
          }
          &.pin-category-5 {
            span {
              background: #1f4a21;
            }
            a {
              color: #1f4a21;
            }
          }
        }
      }
      @media only screen and (min-width: 800px) {
        .map-graphic {
          width: 600px;
          left: 35%;
          transform: translate(-40%, 0);
          img {
            width: 100%;
          }
        }
        .map-category {
          padding-left: 70%;
          ul {
            margin-left: 45%;
          }
        }
      }
    }
    &.visible {
      .map-graphic {
        img {
          opacity: 1;
          transition: opacity 1s;
        }
        .map-pins {
          opacity: 1;
          transition-delay: 0.2s;
        }
      }
      .progress-indicator {
        opacity: 1;
      }
    }
  }
  .modalBackdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    z-index: -1;
    transition: opacity 1s;
    &.active {
      z-index: 14;
      opacity: 1;
    }
  }
  .locationPreview {
    position: fixed;
    top: 12.5%;
    z-index: 15;
    right: -100vw;
    transition: right 0.5s ease-out;
    &.active {
      right: 0;
      @media only screen and (min-width: 800px) {
        right: 30%;
      }
    }
    width: 90%;
    max-width: 380px;
    .bg-image {
      position: absolute;
      top: 260px;
      z-index: 15;
      border: 1px solid #2a623a;
      width: 99.5%;
    }
    .modal {
      .modal-close {
        img {
          width: 15px;
          transform: translateY(3px);
          margin-right: 7px;
        }
        cursor: pointer;
      }
      position: relative;
      background-image: url(../assets/images/modal-top.png);
      background-size: 100%;
      background-position: top left;
      background-repeat: no-repeat;
      padding: 50px 30px;
      color: white;
      z-index: 16;
      h2 {
        margin-bottom: 15px;
      }
      h2 span {
        color: #bcd2a6;
      }
      p {
        font-size: 0.8em;
      }
      .explore {
        text-align: right;
      }
    }
  }
  .about {
    position: relative;
    z-index: 6;
    background-image: url(../assets/images/body-tile-two.png);
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 12% 30px 20px 30px;
    a,
    h4 {
      text-decoration: none;
    }
    h2 {
      font-size: 1.4em;
      line-height: 1.2;
    }
    p {
      color: white;
    }
    div {
      max-width: 450px;
      margin: auto;
      padding-top: 50px;
    }
    @media only screen and (min-width: 800px) {
      background-image: url(../assets/images/body-tile-two-lg.png);
      background-position: bottom;
      padding-top: 25%;
    }
  }
}
</style>

<script>
import DownArrow from "@/components/DownArrow.vue";

export default {
  name: "Home",
  components: {
    DownArrow,
  },
  props: {
    categoryId: Number,
    locationId: Number,
  },
  mounted: function () {
    this.observeMapBackground();
    this.observeMapCategories();
  },
  data() {
    return {
      currentCategory: this.categoryId,
      selectedLocation: this.locationId,
      selectedLocationData: {
        slug: "01-horniman-museum",
      },
      locations: [
        {
          slug: "01-horniman-museum",
          title: "The Horniman Museum and Garden",
          description: "A unique, surprising spot with a view.",
          category: "Learning",
        },
        {
          slug: "02-barbican",
          title: "Barbican Conservatory",
          description: "A tropical oasis hidden in a world-famous art centre.",
          category: "Learning",
        },
        {
          slug: "03-culpeper",
          title: "Culpeper Community Garden",
          description: "A calm retreat in the middle of busy Islington.",
          category: "Learning",
        },
        {
          slug: "04-kyoto",
          title: "Kyoto Garden",
          description:
            "A tranquil, Japanese garden in the heart of West London.",
          category: "Meditating",
        },
        {
          slug: "05-isabella",
          title: "Isabella Plantation",
          description: "Richmond Park’s exotic hidden corner.",
          category: "Meditating",
        },
        {
          slug: "06-hillyfields",
          title: "Hilly Fields",
          description: "A tree-lined retreat with a view.",
          category: "Meditating",
        },
        {
          slug: "07-crystalpalace",
          title: "Crystal Palace Park",
          description: "A ‘pleasure ground’ in the heart of South London.",
          category: "Gathering",
        },
        {
          slug: "08-goldershill",
          title: "Golders Hill Park",
          description: "A secluded corner of bustling Hampstead Heath.",
          category: "Gathering",
        },
        {
          slug: "09-wormwood",
          title: "Wormwood Scrubs",
          description: "West London’s wide-open space.",
          category: "Gathering",
        },
        {
          slug: "10-northgreenwich",
          title: "North Greenwich Riverside",
          description: "Where the busy city meets the gentle river.",
          category: "Exercise",
        },
        {
          slug: "11-osterleypark",
          title: "Osterley Park",
          description: "A vast park centred around a Grade 1 listed mansion.",
          category: "Exercise",
        },
        {
          slug: "12-woodberrywetlands",
          title: "Woodberry Wetlands",
          description: "A North East London’s wildflower wilderness.",
          category: "Exercise",
        },
        {
          slug: "13-haggerston",
          title: "The Haggerston Orchard",
          description: "Nurturing community spirit in East London.",
          category: "Volunteering",
        },
        {
          slug: "14-boilergarden",
          title: "The Boiler Garden",
          description: "A new biodiversity project on a South London estate.",
          category: "Volunteering",
        },
        {
          slug: "15-gaiasgarden",
          title: "Gaia's Garden",
          description: "Community gardening with sustainability at its heart.",
          category: "Volunteering",
        },
      ],
    };
  },
  watch: {
    selectedLocation: function (newLocationId) {
      if (newLocationId > 0) {
        this.selectedLocationData = this.locations[this.selectedLocation - 1];
      }
    },
  },
  methods: {
    getImgUrl(slug) {
      return require("../assets/images/locations/" + slug + "/preview.png");
    },
    handleMapBackgroundIntersection(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    },
    handleCategoryIntersection(entries) {
      entries.map((entry) => {
        var categoryId = parseInt(
          entry.target.getAttribute("data-category-id")
        );
        if (entry.isIntersecting) {
          this.currentCategory = categoryId;
        }
      });
    },
    observeMapBackground() {
      /*
        When the user scrolls the map section, show the map 
        We are using this to show a position:fixed element, this is a workaround to background-attachment fixed not working in iOS
      */
      this.mapTarget = document.querySelector("#mapBox");
      this.mapObserver = new IntersectionObserver(
        this.handleMapBackgroundIntersection,
        { rootMargin: "0px 0px -45% 0px" }
      );
      this.mapObserver.observe(this.mapTarget);
    },
    observeMapCategories() {
      /*
        When the user scrolls the map category into view, highlight the corresponding pins on the map
      */
      const mapCategories = document.querySelectorAll(".map-category");
      mapCategories.forEach((categoryItem) => {
        var categoryObserver = new IntersectionObserver(
          this.handleCategoryIntersection
        );
        categoryObserver.observe(categoryItem);
      });
    },
  },
};
</script>
