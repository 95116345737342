<template>
  <div>
    <div class="content content-intro">
      <div class="player">
        <p>Listen while you read...</p>
        <iframe
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=0&mini=0&feed=%2Fsonos%2Fthe-sound-of-philadelphia-hosted-by-kenny-gamble%2F"
          width="100%"
          height="320"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      <h3>
        The sound of gently rippling waves, a fresh breeze and a breathtaking
        backdrop; North Greenwich riverside is a calm spot in the heart of
        London.
      </h3>
      <p>
        With a stunning view of the City, the riverside is a haven for those
        looking to exercise near the water; you’ll find runners pounding the
        paths, and cyclists winding their way to work. It’s not just those on
        two wheels; North Greenwich is great for those who prefer eight. Roller
        skater Ayisha Alli, known as AyySkates, loves the riverside: “The
        flooring is wonderful for skating, so many skaters love the area. And
        the view is amazing, especially at night. Skaters need a lot of space
        and exercising indoors gets stuffy, so being outdoors eliminates that.”
      </p>
    </div>
    <div class="tile">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-bg">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img src="@/assets/images/about-tile-bg.png" alt="" />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ limit: { min: -5, max: 5 }, reverse: true }"
        class="prlx-tile-img"
      >
        <picture>
         
          <img
            src="@/assets/images/locations/10-northgreenwich/body-1.png"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="content content-break">
      <p>
        Ayisha feels that music helps her escape while she skates: “A lot of the
        spaces I go to are usually filled with other people but once my music is
        on, no one else around me matters. It’s all about me and the freedom I
        have.” The Sonos Roam is perfect for creating the ideal soundtrack as
        you exercise. Lightweight yet incredibly durable, you can bring the
        quality sound with you as you head outside.
      </p>
    </div>
    <blockquote>
      <div v-prlx.mobile="{ limit: { min: -30, max: 30 } }">
        "Exercising outdoors allows people to feel free"
      </div>
    </blockquote>
    <div class="desktop-wrapper">
    <div class="desktop-text">
      <div class="content content-break">
      <h2>Exercising</h2>
      <p>
        The river air and smooth surfaces offer Ayisha an escape, a way to
        unwind: “Exercising outdoors allows people to feel free, it really helps
        with mental health and clarity. Outdoor exercise is free, it’s available
        to everyone. I think it’s a great mood booster when the sun is shining.”
      </p>
    </div>
    <div class="tile-lower">
      <div v-prlx.mobile="{ reverse: false }" class="prlx-tile-lower-img">
        <picture>
          <source
            srcset="@/assets/images/body-tile-one-lg.png"
            media="(min-width: 450px)"
          />
          <img
            src="@/assets/images/locations/10-northgreenwich/body-2.png"
            alt=""
          />
        </picture>
      </div>
      <div
        v-prlx.mobile="{ reverse: true, limit: { min: -25, max: 25 } }"
        class="prlx-tile-lower-graphic"
      >
        <picture>
         
          <img src="@/assets/images/location-body-pattern.png" alt="" />
        </picture>
        <div class="highlights">
          <h3>Did you know?</h3>
          <ul>
            <li>
              The twelve spikes at the top of Greenwich’s O2 Arena (originally
              the Millenium Dome) represent the months of the year.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        North Greenwich offers an ideal space, and stunning backdrop, for
        exercise of all kinds. Ayisha finds it open and accessible: “It gives
        everyone a chance to try something new and be inspired by all the
        different views and atmospheres.”
          </p>
        <p>
          <a href="https://tfl.gov.uk/modes/walking/thames-path" target="_blank" class="btn walking-btn" >Find a nearby walking route</a>
        </p>
        <img
              src="@/assets/images/locations/10-northgreenwich/body-2-lg.png"
              alt=""
              class="desktop-picture"
            />
      </div>
      <div class="base-images">
    
            <img
              src="@/assets/images/locations/10-northgreenwich/body-3-lg.png"
              alt=""
            />
      </div>
    </div>
    <div class="desktop-pictures">
          <img
            src="@/assets/images/locations/10-northgreenwich/body-1-lg.png"
            alt=""
          />
              <img
        src="@/assets/images/locations/10-northgreenwich/body-3.png"
        alt=""
        style="margin-bottom: -15px"
      />
     
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NorthGreenwich",
  components: {},
  inject: ["tabControls"],
  props: {},
  mounted: function () {},
  data() {
    return {};
  },
  methods: {},
};
</script>